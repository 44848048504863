import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '../../../ui/IconButton';
import { LabelStringSet } from '../../../ui/Label';
import Icon, { IconTypes, IconColors } from '../../../ui/Icon';
import InviteMembers from '../../InviteMembers';

import { createChannel, createDefaultUserListQuery } from './utils';
import { CREATE_CHANNEL } from '../dux/actionTypes';

export default function AddChannel({
  sdk,
  disabled,
  channelListDispatcher,
  onBeforeCreateChannel,
  userId,
  userFilledApplicationUserListQuery,
  userListQuery,
}) {
  const [showModal, setshowModal] = useState(false);

  if (!sdk || !sdk.createApplicationUserListQuery) {
    return null;
  }

  return (
    <>
      <IconButton
        height="32px"
        width="32px"
        onClick={() => {
          setshowModal(true);
        }}
        disabled={disabled}
      >
        <Icon
          type={IconTypes.CREATE}
          fillColor={IconColors.PRIMARY}
          width="24px"
          height="24px"
        />
      </IconButton>
      {
        showModal && (
          <InviteMembers
            swapParams={
              sdk && sdk.getErrorFirstCallback && sdk.getErrorFirstCallback()
            }
            titleText={LabelStringSet.MODAL__CREATE_CHANNEL__TITLE}
            submitText={LabelStringSet.BUTTON__CREATE}
            closeModal={() => setshowModal(false)}
            idsToFilter={[userId]}
            userQueryCreator={() => ((userListQuery && typeof userListQuery === 'function')
              ? userListQuery()
              : createDefaultUserListQuery({ sdk, userFilledApplicationUserListQuery })
            )}
            onSubmit={(selectedUsers) => createChannel(sdk, selectedUsers, onBeforeCreateChannel)
              .then((channel) => {
                // maybe - do this in event listener
                channelListDispatcher({
                  type: CREATE_CHANNEL,
                  payload: channel,
                });
              })}
          />
        )
      }
    </>
  );
}

AddChannel.propTypes = {
  sdk: PropTypes.shape({
    getErrorFirstCallback: PropTypes.func,
    createApplicationUserListQuery: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  channelListDispatcher: PropTypes.func.isRequired,
  userFilledApplicationUserListQuery: PropTypes.shape({}),
  onBeforeCreateChannel: PropTypes.func,
  userId: PropTypes.string.isRequired,
  userListQuery: PropTypes.func,
};

AddChannel.defaultProps = {
  disabled: false,
  userFilledApplicationUserListQuery: {},
  onBeforeCreateChannel: null,
  userListQuery: null,
};
