import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Avatar from '../Avatar';
import IconButton from '../IconButton';
import Icon, { IconTypes, IconColors } from '../Icon';
import TextButton from '../TextButton';
import Label, { LabelColors, LabelTypography } from '../Label';
import MessageStatus from '../MessageStatus';
import ContextMenu, { MenuItem, MenuItems } from '../ContextMenu';

import { getSenderProfileUrl, getSenderName, getMessageCreatedAt } from '../../utils/utils';
import {
  truncate,
  getIsSentFromStatus,
} from './utils';
import EmojiReactions from '../EmojiReactions';

const MAX_TRUNCATE_LENGTH = 40;
const SPACE_BETWEEN_MORE = 4;
const MORE_WIDTH = 32;
const noop = () => { };

function checkFileType(fileUrl) {
  let result = null;
  const imageFile = /(\.gif|\.jpg|\.jpeg|\.txt|\.pdf)$/i;
  const audioFile = /(\.mp3)$/i;
  if (imageFile.test(fileUrl)) {
    result = IconTypes.FILE_DOCUMENT;
  } else if (audioFile.test(fileUrl)) {
    result = IconTypes.FILE_AUDIO;
  }
  return result;
}

export function OutgoingFileMessage({
  message,
  userId,
  status,
  showRemove,
  disabled,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const {
    url,
  } = message;
  const openFileUrl = () => { window.open(url); };
  const parentContainRef = useRef(null);
  const menuRef = useRef(null);
  const reactionAddButtonRef = useRef(null);
  const showReactionAddButton = useReaction && emojiAllMap && (emojiAllMap.size > 0);
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const isMessageSent = getIsSentFromStatus(status);

  return (
    <div
      className="sendbird-file-message__outgoing"
      style={{ paddingLeft: `${SPACE_BETWEEN_MORE + (showReactionAddButton ? MORE_WIDTH * 2 : MORE_WIDTH)}px` }}
    >
      <div
        className="sendbird-file-message__more"
        ref={parentContainRef}
      >
        <ContextMenu
          menuTrigger={(toggleDropdown) => (
            <IconButton
              ref={menuRef}
              width="32px"
              height="32px"
              onClick={toggleDropdown}
            >
              <Icon width="24px" height="24px" type={IconTypes.MORE} />
            </IconButton>
          )}
          menuItems={(closeDropdown) => (
            <MenuItems
              parentRef={menuRef}
              parentContainRef={parentContainRef}
              closeDropdown={closeDropdown}
              openLeft
            >
              <MenuItem onClick={() => {
                if (disabled) { return; }
                showRemove(true);
                closeDropdown();
              }}
              >
                Delete
              </MenuItem>
            </MenuItems>
          )}
        />
        {
          showReactionAddButton && (
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  ref={reactionAddButtonRef}
                  width="32px"
                  height="32px"
                  onClick={toggleDropdown}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.EMOJI_REACTIONS_ADD}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MemoizedEmojiListItems
                  message={message}
                  parentRef={reactionAddButtonRef}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                  spaceFromTrigger={{ y: 2 }}
                />
              )}
            />
          )
        }
      </div>
      <div className="sendbird-file-message__tooltip">
        <div className="sendbird-file-message__tooltip__inner">
          {
            checkFileType(url)
              ? (
                <Icon
                  className="sendbird-file-message__tooltip__icon"
                  width="28px"
                  height="28px"
                  type={checkFileType(url)}
                />
              )
              : null
          }
          <TextButton
            className="sendbird-file-message__tooltip__text"
            onClick={openFileUrl}
          >
            <Label
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {truncate(message.url, MAX_TRUNCATE_LENGTH)}
            </Label>
          </TextButton>
        </div>
        {
          (isMessageSent && useReaction && message.reactions && (message.reactions.length > 0)) && (
            <EmojiReactions
              className="sendbird-file-message__tooltip__emoji-reactions"
              userId={userId}
              message={message}
              emojiAllMap={emojiAllMap}
              membersMap={membersMap}
              toggleReaction={toggleReaction}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
        }
      </div>
      <div className="sendbird-file-message__status">
        <MessageStatus
          message={message}
          status={status}
          onDelete={() => { showRemove(true); }}
          onResend={() => resendMessage(message)}
        />
      </div>
    </div>
  );
}

export function IncomingFileMessage({
  message,
  userId,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const openFileUrl = () => { window.open(message.url); };
  const parentContainRef = useRef(null);
  const reactionAddButtonRef = useRef(null);
  const showReactionAddButton = useReaction && emojiAllMap && (emojiAllMap.size > 0);
  const MemoizedEmojiListItems = memoizedEmojiListItems;

  return (
    <div
      className="sendbird-file-message__incoming"
      style={{ paddingRight: `${showReactionAddButton ? MORE_WIDTH + SPACE_BETWEEN_MORE : 0}px` }}
    >
      <Avatar
        className="sendbird-file-message__avatar"
        src={getSenderProfileUrl(message)}
        width="28px"
        height="28px"
      />
      <Label
        className="sendbird-file-message__sender-name"
        type={LabelTypography.CAPTION_2}
        color={LabelColors.ONBACKGROUND_2}
      >
        {getSenderName(message)}
      </Label>
      <div className="sendbird-file-message__tooltip">
        <div className="sendbird-file-message__tooltip__inner">
          {
            checkFileType(message.url)
              ? (
                <Icon
                  className="sendbird-file-message__tooltip__icon"
                  width="28px"
                  height="28px"
                  type={checkFileType(message.url)}
                />
              )
              : null
          }
          <TextButton
            className="sendbird-file-message__tooltip__text"
            onClick={openFileUrl}
          >
            <Label
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {truncate(message.url, MAX_TRUNCATE_LENGTH)}
            </Label>
          </TextButton>
        </div>
        {
          (useReaction && message.reactions && (message.reactions.length > 0)) && (
            <EmojiReactions
              className="sendbird-file-message__tooltip__emoji-reactions"
              userId={userId}
              message={message}
              emojiAllMap={emojiAllMap}
              membersMap={membersMap}
              toggleReaction={toggleReaction}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
        }
      </div>
      <Label
        className="sendbird-file-message__sent-at"
        type={LabelTypography.CAPTION_3}
        color={LabelColors.ONBACKGROUND_2}
      >
        {getMessageCreatedAt(message)}
      </Label>
      {
        showReactionAddButton && (
          <div
            className="sendbird-file-message__more"
            ref={parentContainRef}
          >
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  ref={reactionAddButtonRef}
                  width="32px"
                  height="32px"
                  onClick={toggleDropdown}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.EMOJI_REACTIONS_ADD}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MemoizedEmojiListItems
                  message={message}
                  parentRef={reactionAddButtonRef}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                  spaceFromTrigger={{ y: 2 }}
                />
              )}
            />
          </div>
        )
      }
    </div>
  );
}

OutgoingFileMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  userId: PropTypes.string,
  status: PropTypes.string,
  showRemove: PropTypes.func,
  resendMessage: PropTypes.func,
  useReaction: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

OutgoingFileMessage.defaultProps = {
  status: '',
  showRemove: noop,
  resendMessage: noop,
  message: {},
  userId: '',
  disabled: false,
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};

IncomingFileMessage.propTypes = {
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  userId: PropTypes.string,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

IncomingFileMessage.defaultProps = {
  message: {},
  userId: '',
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};

const MessageSwitch = ({
  message,
  userId,
  disabled,
  isByMe,
  showRemove,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) => (
  <div className={`sendbird-file-message${isByMe ? '--outgoing' : '--incoming'}`}>
    {
      isByMe
        ? (
          <OutgoingFileMessage
            message={message}
            userId={userId}
            disabled={disabled}
            showRemove={showRemove}
            status={status}
            resendMessage={resendMessage}
            useReaction={useReaction}
            emojiAllMap={emojiAllMap}
            membersMap={membersMap}
            toggleReaction={toggleReaction}
            memoizedEmojiListItems={memoizedEmojiListItems}
          />
        )
        : (
          <IncomingFileMessage
            userId={userId}
            message={message}
            useReaction={useReaction}
            emojiAllMap={emojiAllMap}
            membersMap={membersMap}
            toggleReaction={toggleReaction}
            memoizedEmojiListItems={memoizedEmojiListItems}
          />
        )
    }
  </div>
);


MessageSwitch.propTypes = {
  message: PropTypes.shape({}),
  userId: PropTypes.string,
  isByMe: PropTypes.bool,
  disabled: PropTypes.bool,
  showRemove: PropTypes.func,
  resendMessage: PropTypes.func,
  status: PropTypes.string.isRequired,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

MessageSwitch.defaultProps = {
  message: {},
  isByMe: false,
  disabled: false,
  showRemove: noop,
  resendMessage: noop,
  userId: '',
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};

export default MessageSwitch;
