import Moment from 'moment';

export const getMessageCreatedAt = (message) => Moment(message.createdAt).format('LT');

export const getSenderName = (message) => (
  message.sender && (
    message.sender.friendName
    || message.sender.nickname
    || message.sender.userId
  )
);

export const getSenderProfileUrl = (message) => message.sender && message.sender.profileUrl;

export default {
  getMessageCreatedAt,
  getSenderName,
  getSenderProfileUrl,
};
