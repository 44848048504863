import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Avatar from '../Avatar';
import Icon, { IconTypes, IconColors } from '../Icon';
import IconButton from '../IconButton';
import MessageStatus from '../MessageStatus';
import Label, { LabelTypography, LabelColors } from '../Label';
import ContextMenu, { MenuItem, MenuItems } from '../ContextMenu';
import EmojiReactions from '../EmojiReactions';
import {
  copyToClipboard,
  getMessageCreatedAt,
  getSenderName,
  getSenderProfileUrl,
  getIsSentFromStatus,
} from './util';

const noop = () => { };
const SPACE_BETWEEN_MORE = 4;
const MORE_WIDTH = 32;

export default function Message(props) {
  const {
    isByMe,
    userId,
    message,
    className,
    resendMessage,
    disabled,
    showEdit,
    showRemove,
    status,
    useReaction,
    emojiAllMap,
    membersMap,
    toggleReaction,
    memoizedEmojiListItems,
  } = props;

  if (!message) return null;

  const injectingClassName = (Array.isArray(className)) ? className : [className];
  injectingClassName.push(`sendbird-message${isByMe ? '--outgoing' : '--incoming'}`);

  return (
    <div className={[...injectingClassName, 'sendbird-message'].join(' ')}>
      {
        isByMe
          ? (
            <OutgoingUserMessage
              userId={userId}
              message={message}
              resendMessage={resendMessage}
              disabled={disabled}
              showEdit={showEdit}
              showRemove={showRemove}
              status={status}
              useReaction={useReaction}
              emojiAllMap={emojiAllMap}
              membersMap={membersMap}
              toggleReaction={toggleReaction}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
          : (
            <IncomingUserMessage
              userId={userId}
              message={message}
              useReaction={useReaction}
              emojiAllMap={emojiAllMap}
              membersMap={membersMap}
              toggleReaction={toggleReaction}
              memoizedEmojiListItems={memoizedEmojiListItems}
            />
          )
      }
    </div>
  );
}

Message.propTypes = {
  isByMe: PropTypes.bool,
  disabled: PropTypes.bool,
  userId: PropTypes.string,
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showEdit: PropTypes.func,
  status: PropTypes.string,
  showRemove: PropTypes.func,
  resendMessage: PropTypes.func,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

Message.defaultProps = {
  isByMe: false,
  disabled: false,
  userId: '',
  resendMessage: noop,
  className: '',
  showEdit: noop,
  showRemove: noop,
  status: '',
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};

function OutgoingUserMessage({
  userId,
  message,
  showEdit,
  disabled,
  showRemove,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  // TODO: when message.requestState is succeeded, consider if it's SENT or DELIVERED
  const parentRefReactions = useRef(null);
  const parentRefMenus = useRef(null);
  const parentContainRef = useRef(null);
  const isMessageSent = getIsSentFromStatus(status);

  return (
    <div
      className="sendbird-user-message--outgoing"
      style={{ paddingLeft: `${SPACE_BETWEEN_MORE + (useReaction ? MORE_WIDTH * 2 : MORE_WIDTH)}px` }}
    >
      <div className="sendbird-user-message__more" ref={parentContainRef}>
        <ContextMenu
          menuTrigger={(toggleDropdown) => (
            <IconButton
              className="sendbird-user-message__more__menu"
              ref={parentRefMenus}
              width="32px"
              height="32px"
              onClick={toggleDropdown}
            >
              <Icon
                width="24px"
                height="24px"
                type={IconTypes.MORE}
                fillColor={IconColors.CONTENT_INVERSE}
              />
            </IconButton>
          )}
          menuItems={(closeDropdown) => (
            <MenuItems
              parentRef={parentRefMenus} // for catching location(x, y) of MenuItems
              parentContainRef={parentContainRef} // for toggling more options(menus & reactions)
              closeDropdown={closeDropdown}
              openLeft
            >
              {
                isMessageSent && (
                  <MenuItem
                    className="sendbird-user-message--copy"
                    onClick={() => { copyToClipboard(message.message); closeDropdown(); }}
                  >
                    Copy
                  </MenuItem>
                )
              }
              {
                isMessageSent && (
                  <MenuItem
                    onClick={() => {
                      if (disabled) { return; }
                      showEdit(true);
                      closeDropdown();
                    }}
                  >
                    Edit
                  </MenuItem>
                )
              }
              <MenuItem
                onClick={() => {
                  if (disabled) { return; }
                  showRemove(true);
                  closeDropdown();
                }}
              >
                Delete
              </MenuItem>
            </MenuItems>
          )}
        />
        {
          (isMessageSent && useReaction && (emojiAllMap.size > 0))
          && (
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  className="sendbird-user-message__more__add-reaction"
                  ref={parentRefReactions}
                  width="32px"
                  height="32px"
                  onClick={toggleDropdown}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.EMOJI_REACTIONS_ADD}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MemoizedEmojiListItems
                  message={message}
                  parentRef={parentRefReactions}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                  spaceFromTrigger={{ y: 2 }}
                />
              )}
            />
          )
        }
      </div>
      <div className="sendbird-user-message__text-balloon">
        <div className="sendbird-user-message__text-balloon__inner">
          <div className="sendbird-user-message__text-balloon__inner__text-place">
            <Label
              className="sendbird-user-message__text-balloon__inner__text-place__text"
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {message.message}
            </Label>
          </div>
          {
            (useReaction && message.reactions && message.reactions.length > 0)
            && (
              <EmojiReactions
                className="sendbird-user-message__text-balloon__inner__emoji-reactions"
                userId={userId}
                message={message}
                emojiAllMap={emojiAllMap}
                membersMap={membersMap}
                toggleReaction={toggleReaction}
                memoizedEmojiListItems={memoizedEmojiListItems}
              />
            )
          }

        </div>
      </div>
      <div className="sendbird-user-message__status">
        <MessageStatus
          message={message}
          status={status}
          onDelete={() => { showRemove(true); }}
          onResend={() => resendMessage(message)}
        />
      </div>
    </div>
  );
}

function IncomingUserMessage({
  userId,
  message,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const parentRefReactions = useRef(null);
  const parentRefMenus = useRef(null);
  const parentContainRef = useRef(null);
  const showReactionAddButton = useReaction && emojiAllMap && (emojiAllMap.size > 0);

  return (
    <div
      className="sendbird-user-message--incoming"
      style={{ paddingRight: `${SPACE_BETWEEN_MORE + (showReactionAddButton ? MORE_WIDTH * 2 : MORE_WIDTH)}px` }}
    >
      <Avatar
        className="sendbird-user-message__avatar"
        src={getSenderProfileUrl(message)}
        width="28px"
        height="28px"
      />
      <Label
        className="sendbird-user-message__sender-name"
        type={LabelTypography.CAPTION_2}
        color={LabelColors.ONBACKGROUND_2}
      >
        {getSenderName(message)}
      </Label>
      <div className="sendbird-user-message__text-balloon">
        <div className="sendbird-user-message__text-balloon__inner">
          <div className="sendbird-user-message__text-balloon__inner__text-place">
            <Label
              className="sendbird-user-message__text-balloon__inner__text-place__text"
              type={LabelTypography.BODY_1}
              color={LabelColors.ONBACKGROUND_1}
            >
              {message.message}
            </Label>
          </div>
          {
            (useReaction && message.reactions && message.reactions.length > 0)
            && (
              <EmojiReactions
                className="sendbird-user-message__text-balloon__inner__emoji-reactions"
                userId={userId}
                message={message}
                emojiAllMap={emojiAllMap}
                membersMap={membersMap}
                toggleReaction={toggleReaction}
                memoizedEmojiListItems={memoizedEmojiListItems}
              />
            )
          }
        </div>
      </div>
      <Label
        className="sendbird-user-message__sent-at"
        type={LabelTypography.CAPTION_3}
        color={LabelColors.ONBACKGROUND_2}
      >
        {getMessageCreatedAt(message)}
      </Label>
      <div className="sendbird-user-message__more" ref={parentContainRef}>
        {
          showReactionAddButton
          && (
            <ContextMenu
              menuTrigger={(toggleDropdown) => (
                <IconButton
                  ref={parentRefReactions}
                  width="32px"
                  height="32px"
                  onClick={toggleDropdown}
                >
                  <Icon
                    width="24px"
                    height="24px"
                    type={IconTypes.EMOJI_REACTIONS_ADD}
                    fillColor={IconColors.CONTENT_INVERSE}
                  />
                </IconButton>
              )}
              menuItems={(closeDropdown) => (
                <MemoizedEmojiListItems
                  parentRef={parentRefReactions}
                  parentContainRef={parentContainRef}
                  closeDropdown={closeDropdown}
                  message={message}
                  spaceFromTrigger={{ y: 2 }}
                />
              )}
            />
          )
        }
        <ContextMenu
          menuTrigger={(toggleDropdown) => (
            <IconButton
              ref={parentRefMenus}
              width="32px"
              height="32px"
              onClick={toggleDropdown}
            >
              <Icon
                width="24px"
                height="24px"
                type={IconTypes.MORE}
                fillColor={IconColors.CONTENT_INVERSE}
              />
            </IconButton>
          )}
          menuItems={(closeDropdown) => (
            <MenuItems
              parentRef={parentRefMenus}
              parentContainRef={parentContainRef}
              closeDropdown={closeDropdown}
            >
              <MenuItem
                className="sendbird-user-message--copy"
                onClick={() => { copyToClipboard(message.message); closeDropdown(); }}
              >
                Copy
              </MenuItem>
            </MenuItems>
          )}
        />
      </div>
    </div>
  );
}

IncomingUserMessage.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

IncomingUserMessage.defaultProps = {
  message: {},
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};

OutgoingUserMessage.propTypes = {
  userId: PropTypes.string.isRequired,
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  showEdit: PropTypes.func,
  showRemove: PropTypes.func,
  disabled: PropTypes.bool,
  resendMessage: PropTypes.func,
  status: PropTypes.string.isRequired,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

OutgoingUserMessage.defaultProps = {
  message: {},
  resendMessage: noop,
  showEdit: noop,
  showRemove: noop,
  disabled: false,
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};
