export const RESET_CHANNEL_LIST = 'RESET_CHANNEL_LIST';
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const UNLOAD_CHANNELS = 'UNLOAD_CHANNELS';
export const SET_CHANNEL_LOADING = 'SET_CHANNEL_LOADING';
export const LEAVE_CHANNEL_SUCCESS = 'LEAVE_CHANNEL_SUCCESS';

export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';

export const SHOW_CHANNEL_SETTINGS = 'SHOW_CHANNEL_SETTINGS';
export const HIDE_CHANNEL_SETTINGS = 'HIDE_CHANNEL_SETTINGS';

export const FETCH_CHANNELS_START = 'FETCH_CHANNELS_START';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';

export const INIT_CHANNELS_START = 'INIT_CHANNELS_START';
export const INIT_CHANNELS_SUCCESS = 'INIT_CHANNELS_SUCCESS';
export const INIT_CHANNELS_FAILURE = 'INIT_CHANNELS_FAILURE';

export const INVITE_MEMBERS_SUCESS = 'INVITE_MEMBERS_SUCESS';

export const ON_USER_JOINED = 'ON_USER_JOINED';
export const ON_CHANNEL_DELETED = 'ON_CHANNEL_DELETED';
export const ON_LAST_MESSAGE_UPDATED = 'ON_LAST_MESSAGE_UPDATED';
export const ON_USER_LEFT = 'ON_USER_LEFT';
export const ON_CHANNEL_CHANGED = 'ON_CHANNEL_CHANGED';
export const ON_CHANNEL_ARCHIVED = 'ON_CHANNEL_ARCHIVED';

export const ON_READ_RECEIPT_UPDATED = 'ON_READ_RECEIPT_UPDATED';
export const ON_DELIVERY_RECEIPT_UPDATED = 'ON_DELIVERY_RECEIPT_UPDATED';

export const CHANNEL_REPLACED_TO_TOP = 'CHANNEL_REPLACED_TO_TOP';
