import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Message from '../../../ui/Message';
import AdminMessage from '../../../ui/AdminMessage';
import ThumbnailMessage from '../../../ui/ThumbnailMessage';
import FileMessage from '../../../ui/FileMessage';
import DateSeparator from '../../../ui/DateSeparator';
import Label, { LabelTypography, LabelColors } from '../../../ui/Label';
import MessageInput from '../../../ui/MessageInput';
import FileViewer from '../../../ui/FileViewer';
import RemoveMessageModal from './RemoveMessage';
import UnknownMessage from '../../../ui/UnknownMessage';

import { isImage, isVideo } from '../../../ui/FileViewer/types';

export default function MessageHoc({
  message,
  userId,
  disabled,
  editDisabled,
  hasSeperator,
  deleteMessage,
  updateMessage,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const { sender = {} } = message;
  const [showEdit, setShowEdit] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const editMessageInputRef = useRef(null);

  const isByMe = (userId === sender.userId)
    || (message.requestState === 'pending')
    || (message.requestState === 'failed');

  if (showEdit) {
    return (
      <MessageInput
        isEdit
        disabled={editDisabled}
        ref={editMessageInputRef}
        name={message.messageId}
        onSendMessage={updateMessage}
        onCancelEdit={() => { setShowEdit(false); }}
        value={message.message}
      />
    );
  }

  return (
    <div className="sendbird-msg-hoc sendbird-msg--scroll-ref">
      {/* date-seperator */}
      {
        hasSeperator && (
          <DateSeparator>
            <Label type={LabelTypography.CAPTION_2} color={LabelColors.ONBACKGROUND_2}>
              {moment(message.createdAt).format('LL')}
            </Label>
          </DateSeparator>
        )
      }
      {/* file message */}
      {
        ((message.isFileMessage && message.isFileMessage()) || message.messageType === 'file') && (
          <>
            {
              isImage(message.type) || isVideo(message.type)
                ? (
                  <ThumbnailMessage
                    disabled={disabled}
                    message={message}
                    userId={userId}
                    isByMe={isByMe}
                    showRemove={setShowRemove}
                    resendMessage={resendMessage}
                    onClick={setShowFileViewer}
                    status={status}
                    useReaction={useReaction}
                    emojiAllMap={emojiAllMap}
                    membersMap={membersMap}
                    toggleReaction={toggleReaction}
                    memoizedEmojiListItems={memoizedEmojiListItems}
                  />
                )
                : (
                  <FileMessage
                    message={message}
                    userId={userId}
                    disabled={disabled}
                    isByMe={isByMe}
                    showRemove={setShowRemove}
                    resendMessage={resendMessage}
                    status={status}
                    useReaction={useReaction}
                    emojiAllMap={emojiAllMap}
                    membersMap={membersMap}
                    toggleReaction={toggleReaction}
                    memoizedEmojiListItems={memoizedEmojiListItems}
                  />
                )
            }
          </>
        )
      }
      {/* admin message */}
      {
        message.isAdminMessage && message.isAdminMessage() && (
          <AdminMessage message={message} />
        )
      }
      {/* user message */}
      {
        ((message.isUserMessage && message.isUserMessage()) || message.messageType === 'user')
        && (
          <Message
            message={message}
            disabled={disabled}
            isByMe={isByMe}
            userId={userId}
            showEdit={setShowEdit}
            showRemove={setShowRemove}
            resendMessage={resendMessage}
            status={status}
            useReaction={useReaction}
            emojiAllMap={emojiAllMap}
            membersMap={membersMap}
            toggleReaction={toggleReaction}
            memoizedEmojiListItems={memoizedEmojiListItems}
          />
        )
      }
      {/* Modal */}
      {
        showRemove && (
          <RemoveMessageModal
            onCloseModal={() => setShowRemove(false)}
            onDeleteMessage={() => {
              deleteMessage(message);
            }}
          />
        )
      }
      {
        showFileViewer && (
          <FileViewer
            onClose={() => setShowFileViewer(false)}
            message={message}
            onDelete={() => {
              deleteMessage(message, () => {
                setShowFileViewer(false);
              });
            }}
            isByMe={isByMe}
          />
        )
      }
      {
        !((message.isFileMessage && message.isFileMessage()) || message.messageType === 'file')
        && !(message.isAdminMessage && message.isAdminMessage())
        && !(((message.isUserMessage && message.isUserMessage()) || message.messageType === 'user'))
        && !(showFileViewer)
        && (
          <UnknownMessage
            message={message}
            status={status}
            isByMe={isByMe}
            showRemove={setShowRemove}
          />
        )
      }
    </div>
  );
}

MessageHoc.propTypes = {
  userId: PropTypes.string,
  message: PropTypes.shape({
    isFileMessage: PropTypes.func,
    isAdminMessage: PropTypes.func,
    isUserMessage: PropTypes.func,
    isDateSeperator: PropTypes.func,
    // should be a number, but there's a bug in SDK shich returns string
    messageId: PropTypes.number,
    type: PropTypes.string,
    createdAt: PropTypes.number,
    message: PropTypes.string,
    requestState: PropTypes.string,
    messageType: PropTypes.string,
    sender: PropTypes.shape({ userId: PropTypes.string }),
  }),
  hasSeperator: PropTypes.bool,
  disabled: PropTypes.bool,
  editDisabled: PropTypes.bool,
  deleteMessage: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  resendMessage: PropTypes.func.isRequired,
  status: PropTypes.string,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map).isRequired,
  membersMap: PropTypes.instanceOf(Map).isRequired,
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

MessageHoc.defaultProps = {
  userId: '',
  editDisabled: false,
  message: {},
  hasSeperator: false,
  disabled: false,
  status: '',
  toggleReaction: () => { },
  memoizedEmojiListItems: () => '',
};
