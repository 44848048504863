import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './members-accordion.scss';

import Button from '../../../ui/Button';
import {
  Type as ButtonTypes,
  Size as ButtonSizes,
} from '../../../ui/Button/type';
import Avatar from '../../../ui/Avatar';
import Label, { LabelTypography, LabelColors, LabelStringSet } from '../../../ui/Label';
import Modal from '../../../ui/Modal';
import InviteMembers from '../../InviteMembers';
import UserListItem from '../../../ui/UserListItem';

const SHOWN_MEMBER_MAX = 10;

const MemebersAccordion = ({
  members,
  disabled,
  userQueryCreator,
  onInviteMemebers,
  swapParams,
}) => {
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  return (
    <div className="sendbird-members-accordion">
      <div className="sendbird-members-accordion__list">
        {
          members.slice(0, SHOWN_MEMBER_MAX).map((member) => (
            <div className="sendbird-members-accordion__member" key={member.userId}>
              <div className="sendbird-members-accordion__member-avatar">
                <Avatar height="24px" width="24px" src={member.profileUrl} />
              </div>
              <Label type={LabelTypography.SUBTITLE_2} color={LabelColors.ONBACKGROUND_1}>
                {member.nickname || LabelStringSet.NO_NAME}
              </Label>
            </div>
          ))
        }
      </div>
      <div className="sendbird-members-accordion__footer">
        {
          members.length >= SHOWN_MEMBER_MAX && (
            <Button
              className="sendbird-members-accordion__footer__all-members"
              type={ButtonTypes.SECONDARY}
              size={ButtonSizes.SMALL}
              onClick={() => setShowMoreModal(true)}
            >
              {LabelStringSet.CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS}
            </Button>
          )
        }
        {
          members.length >= SHOWN_MEMBER_MAX && showMoreModal && (
            <Modal
              hideFooter
              onCancel={() => setShowMoreModal(false)}
              onSubmit={() => { }}
              titleText={`${members.length} ${LabelStringSet.MODAL__USER_LIST__TITLE}`}
            >
              <div className="sendbird-more-members__popup-scroll">
                {members.map((member) => <UserListItem user={member} key={member.userId} />)}
              </div>
            </Modal>
          )
        }
        <Button
          className="sendbird-members-accordion__footer__invite-users"
          type={ButtonTypes.SECONDARY}
          size={ButtonSizes.SMALL}
          disabled={disabled}
          onClick={() => {
            if (disabled) { return; }
            setShowAddUserModal(true);
          }}
        >
          {LabelStringSet.CHANNEL_SETTING__MEMBERS__INVITE_MEMBER}
        </Button>
        {
          showAddUserModal && (
            <InviteMembers
              swapParams={swapParams}
              titleText={LabelStringSet.MODAL__INVITE_MEMBER__TITLE}
              submitText={LabelStringSet.BUTTON__INVITE}
              closeModal={() => setShowAddUserModal(false)}
              idsToFilter={members.map((member) => (member.userId))}
              userQueryCreator={userQueryCreator}
              onSubmit={onInviteMemebers}
            />
          )
        }
      </div>
    </div>
  );
};

MemebersAccordion.propTypes = {
  swapParams: PropTypes.bool,
  disabled: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  userQueryCreator: PropTypes.func.isRequired,
  onInviteMemebers: PropTypes.func.isRequired,
};

MemebersAccordion.defaultProps = {
  swapParams: false,
  disabled: false,
  members: [],
};

export default MemebersAccordion;
