import React, {
  useEffect,
  useRef,
  useReducer,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import * as utils from './utils';

import withSendbirdContext from '../../lib/SendbirdSdkContext';
import * as messageActionTypes from './dux/actionTypes';
import messagesInitialState from './dux/initialState';
import messagesReducer from './dux/reducers';

import useHandleChannelEvents from './hooks/useHandleChannelEvents';
import useGetChannel from './hooks/useGetChannel';
import useInitialMessagesFetch from './hooks/useInitialMessagesFetch';
import useHandleReconnect from './hooks/useHandleReconnect';
import useScrollCallback from './hooks/useScrollCallback';
import useDeleteMessageCallback from './hooks/useDeleteMessageCallback';
import useUpdateMessageCallback from './hooks/useUpdateMessageCallback';
import useResendMessageCallback from './hooks/useResendMessageCallback';
import useSendMessageCallback from './hooks/useSendMessageCallback';
import useSendFileMessageCallback from './hooks/useSendFileMessageCallback';
import useSetReadStatus from './hooks/useSetReadStatus';
import useMemoizedEmojiListItems from './hooks/useMemoizedEmojiListItems';
import useToggleReactionCallback from './hooks/useToggleReactionCallback';

import ConversationScroll from './components/ConversationScroll';
import Notification from './components/Notification';
import TypingIndicator from './components/TypingIndicator';
import ConnectionStatus from '../../ui/ConnectionStatus';
import ChatHeader from '../../ui/ChatHeader';
import MessageInput from '../../ui/MessageInput';
import PlaceHolder from '../../ui/PlaceHolder';
import PlaceHolderTypes from '../../ui/PlaceHolder/type';
import { LabelStringSet } from '../../ui/Label';

const noop = () => { };

export const ConversationPanel = (props) => {
  const {
    channelUrl,
    stores: { sdkStore, userStore },
    config: {
      userId,
      logger,
      pubSub,
      isOnline,
      theme,
    },
    dispatchers: {
      reconnect,
    },
    queries = {},
    renderChatItem,
    renderMessageInput,
    renderChatHeader,
    onChatHeaderActionClick,
    onBeforeSendUserMessage,
    onBeforeSendFileMessage,
    onBeforeUpdateUserMessage,
  } = props;
  const { sdk } = sdkStore;
  const sdkError = sdkStore.error;
  const sdkInit = sdkStore.initialized;
  const { user } = userStore;

  if (queries.messageListQuery) {
    // eslint-disable-next-line no-console
    console.warn('messageListQuery will be deprecared in v1.3.0, please use messageListParams instead');
  }

  const userFilledMessageListQuery = queries.messageListParams || queries.messageListQuery;

  const [messagesStore, messagesDispatcher] = useReducer(messagesReducer, messagesInitialState);
  const scrollRef = useRef(null);

  const { appInfo = {} } = sdk;
  const useReaction = appInfo.isUsingReaction || false;

  const {
    allMessages,
    loading,
    hasMore,
    initialized,
    unreadCount,
    unreadSince,
    invalidChannel,
    currentGroupChannel,
    lastMessageTimeStamp,
    emojiContainer,
    readStatus,
  } = messagesStore;

  const emojiAllMap = useMemo(() => (
    useReaction
      ? utils.getAllEmojisMapFromEmojiContainer(emojiContainer)
      : new Map()
  ), [emojiContainer]);

  const emojiAllList = useMemo(() => (
    useReaction
      ? utils.getAllEmojisFromEmojiContainer(emojiContainer)
      : []
  ), [emojiContainer]);

  const nicknamesMap = useMemo(() => (
    useReaction
      ? utils.getNicknamesMapFromMembers(currentGroupChannel.members)
      : new Map()
  ), [currentGroupChannel.members]);

  const onScrollCallback = useScrollCallback({
    currentGroupChannel, lastMessageTimeStamp, userFilledMessageListQuery,
  }, {
    hasMore,
    logger,
    messagesDispatcher,
    sdk,
  });

  const toggleReaction = useToggleReactionCallback({ currentGroupChannel }, { logger });

  const memoizedEmojiListItems = useMemoizedEmojiListItems({
    emojiContainer, toggleReaction,
  }, {
    useReaction,
    logger,
    userId,
    emojiAllList,
  });

  // to create message-datasource
  useGetChannel(
    { channelUrl, sdkInit },
    { messagesDispatcher, sdk, logger },
  );

  // Hook to handle ChannelEvents and send values to useReducer using messagesDispatcher
  useHandleChannelEvents(
    { currentGroupChannel, sdkInit },
    { messagesDispatcher, sdk, logger },
  );

  useInitialMessagesFetch({ currentGroupChannel }, {
    sdk,
    logger,
    messagesDispatcher,
    userFilledMessageListQuery,
  });

  // handles API calls from withSendbird
  useEffect(() => {
    const subScriber = utils.pubSubHandler(channelUrl, pubSub, messagesDispatcher);
    return () => {
      utils.pubSubHandleRemover(subScriber);
    };
  }, [channelUrl, sdkInit]);

  // to create initial read status
  useSetReadStatus(
    { allMessages, currentGroupChannel },
    { messagesDispatcher, sdk, logger },
  );

  // handling connection breaks
  useHandleReconnect({ isOnline }, {
    logger,
    sdk,
    currentGroupChannel,
    messagesDispatcher,
    userFilledMessageListQuery,
  });


  const deleteMessage = useDeleteMessageCallback({ currentGroupChannel, messagesDispatcher },
    { logger });
  const updateMessage = useUpdateMessageCallback(
    { currentGroupChannel, messagesDispatcher, onBeforeUpdateUserMessage },
    { logger, sdk, pubSub },
  );
  const resendMessage = useResendMessageCallback(
    { currentGroupChannel, messagesDispatcher },
    { logger },
  );
  const [messageInputRef, onSendMessage] = useSendMessageCallback(
    { currentGroupChannel, onBeforeSendUserMessage },
    {
      sdk,
      logger,
      pubSub,
      messagesDispatcher,
    },
  );
  const [onSendFileMessage] = useSendFileMessageCallback(
    { currentGroupChannel, onBeforeSendFileMessage },
    {
      sdk,
      logger,
      pubSub,
      messagesDispatcher,
    },
  );

  const inputDisabled = !initialized
  || utils.isDisabledBecauseFrozen(currentGroupChannel) || !isOnline;

  if (sdkError) {
    return (
      <div className="sendbird-conversation">
        <PlaceHolder
          type={PlaceHolderTypes.WRONG}
          retryToConnect={() => {
            logger.info('Channel: reconnecting');
            reconnect();
          }}
        />
      </div>
    );
  }
  if (!channelUrl) {
    return (<div className="sendbird-conversation"><PlaceHolder type={PlaceHolderTypes.NO_CHANNELS} /></div>);
  }
  if (loading) {
    return (<div className="sendbird-conversation"><PlaceHolder type={PlaceHolderTypes.LOADING} /></div>);
  }
  if (invalidChannel) {
    return (<div className="sendbird-conversation"><PlaceHolder type={PlaceHolderTypes.WRONG} /></div>);
  }

  return (
    <div className="sendbird-conversation">
      {
        renderChatHeader
          ? renderChatHeader({ channel: currentGroupChannel, user })
          : (
            <ChatHeader
              theme={theme}
              currentGroupChannel={currentGroupChannel}
              currentUser={user}
              onActionClick={onChatHeaderActionClick}
              subTitle={currentGroupChannel.members && currentGroupChannel.members.length !== 2}
              isActive={false}
              isMuted={false}
            />
          )
      }
      {
        unreadCount > 0 && (
          <Notification
            count={unreadCount}
            onClick={() => {
              utils.scrollIntoLast('.sendbird-msg--scroll-ref');
              // there is no scroll
              if (scrollRef.current.scrollTop === 0) {
                currentGroupChannel.markAsRead();
                messagesDispatcher({
                  type: messageActionTypes.MARK_AS_READ,
                });
              }
            }}
            time={unreadSince}
          />
        )
      }
      <ConversationScroll
        swapParams={
          sdk && sdk.getErrorFirstCallback && sdk.getErrorFirstCallback()
        }
        scrollRef={scrollRef}
        hasMore={hasMore}
        disabled={!isOnline}
        messagesDispatcher={messagesDispatcher}
        onScroll={onScrollCallback}
        initialized={initialized}
        allMessages={allMessages}
        userId={userId}
        editDisabled={utils.isDisabledBecauseFrozen(currentGroupChannel)}
        readStatus={readStatus}
        currentGroupChannel={currentGroupChannel}
        renderChatItem={renderChatItem}
        useReaction={useReaction}
        emojiContainer={emojiContainer}
        emojiAllMap={emojiAllMap}
        membersMap={nicknamesMap}
        memoizedEmojiListItems={memoizedEmojiListItems}
        deleteMessage={deleteMessage}
        updateMessage={updateMessage}
        resendMessage={resendMessage}
        toggleReaction={toggleReaction}
      />
      <div className="sendbird-conversation__footer">
        {
          renderMessageInput
            ? renderMessageInput({ channel: currentGroupChannel, user, disabled: inputDisabled })
            : (
              <MessageInput
                placeholder={utils.isDisabledBecauseFrozen(currentGroupChannel)
                  && LabelStringSet.CHANNEL__MESSAGE_INPUT__PLACE_HOLDER__DISABLED}
                ref={messageInputRef}
                disabled={inputDisabled}
                onStartTyping={() => {
                  currentGroupChannel.startTyping();
                }}
                onSendMessage={onSendMessage}
                onFileUpload={onSendFileMessage}
              />
            )
        }
        <div className="sendbird-conversation__typing-indicator">
          <TypingIndicator channelUrl={channelUrl} sb={sdk} logger={logger} />
        </div>
        {
          !isOnline && (
            <ConnectionStatus sdkInit={sdkInit} sb={sdk} logger={logger} />
          )
        }
      </div>
    </div>
  );
};

ConversationPanel.propTypes = {
  channelUrl: PropTypes.string,
  stores: PropTypes.shape({
    sdkStore: PropTypes.shape({
      initialized: PropTypes.bool,
      sdk: PropTypes.shape({
        getErrorFirstCallback: PropTypes.func,
        removeChannelHandler: PropTypes.func,
        GroupChannel: PropTypes.any,
        ChannelHandler: PropTypes.any,
        addChannelHandler: PropTypes.func,
        UserMessageParams: PropTypes.any,
        FileMessageParams: PropTypes.any,
        getAllEmoji: PropTypes.func,
        appInfo: PropTypes.shape({}),
      }),
      error: PropTypes.bool,
    }),
    userStore: PropTypes.shape({
      user: PropTypes.shape({}),
    }),
  }).isRequired,
  dispatchers: PropTypes.shape({
    reconnect: PropTypes.func,
  }).isRequired,
  config: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    isOnline: PropTypes.bool.isRequired,
    theme: PropTypes.string,
    logger: PropTypes.shape({
      info: PropTypes.func,
      error: PropTypes.func,
      warning: PropTypes.func,
    }),
    pubSub: PropTypes.shape({
      subscribe: PropTypes.func,
      publish: PropTypes.func,
    }),
  }).isRequired,
  queries: PropTypes.shape({
    messageListParams: PropTypes.shape({
      includeMetaArray: PropTypes.bool,
      includeParentMessageText: PropTypes.bool,
      includeReaction: PropTypes.bool,
      includeReplies: PropTypes.bool,
      includeThreadInfo: PropTypes.bool,
      limit: PropTypes.number,
      reverse: PropTypes.bool,
      senderUserIdsFilter: PropTypes.arrayOf(PropTypes.string),
    }),
    // deprecate in v1.3
    messageListQuery: PropTypes.shape({
      includeMetaArray: PropTypes.bool,
      includeParentMessageText: PropTypes.bool,
      includeReaction: PropTypes.bool,
      includeReplies: PropTypes.bool,
      includeThreadInfo: PropTypes.bool,
      limit: PropTypes.number,
      reverse: PropTypes.bool,
      senderUserIdsFilter: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  onBeforeSendUserMessage: PropTypes.func, // onBeforeSendUserMessage(text)
  onBeforeSendFileMessage: PropTypes.func, // onBeforeSendFileMessage(File)
  onBeforeUpdateUserMessage: PropTypes.func,
  renderChatItem: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  renderMessageInput: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  renderChatHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  onChatHeaderActionClick: PropTypes.func,
};

ConversationPanel.defaultProps = {
  channelUrl: null,
  queries: {},
  onBeforeSendUserMessage: null,
  onBeforeSendFileMessage: null,
  onBeforeUpdateUserMessage: null,
  renderChatItem: null,
  renderMessageInput: null,
  renderChatHeader: null,
  onChatHeaderActionClick: noop,
};

export const {
  getEmojiCategoriesFromEmojiContainer,
  getAllEmojisFromEmojiContainer,
  getEmojisFromEmojiContainer,
} = utils;

export default withSendbirdContext(ConversationPanel);
