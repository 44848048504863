import { useCallback } from 'react';

import * as messageActionTypes from '../dux/actionTypes';
import * as utils from '../utils';
import * as topics from '../../../lib/pubSub/topics';

export default function useSendFileMessageCallback({
  currentGroupChannel, onBeforeSendFileMessage,
}, {
  sdk,
  logger,
  pubSub,
  messagesDispatcher,
}) {
  const sendMessage = useCallback((file) => {
    const createParamsDefault = (file_) => {
      const params = new sdk.FileMessageParams();
      params.file = file_;
      return params;
    };

    const createCustomPrams = onBeforeSendFileMessage
      && typeof onBeforeSendFileMessage === 'function';

    if (createCustomPrams) {
      logger.info('Channel: creting params using onBeforeSendFileMessage', onBeforeSendFileMessage);
    }

    const params = onBeforeSendFileMessage
      ? onBeforeSendFileMessage(file)
      : createParamsDefault(file);

    logger.info('Channel: Uploading file message start!', params);
    const pendingMsg = currentGroupChannel.sendFileMessage(params, (response, err) => {
      const swapParams = sdk.getErrorFirstCallback();
      let message = response;
      let error = err;
      if (swapParams) {
        message = err;
        error = response;
      }
      if (error) {
        // sending params instead of pending message
        // to make sure that we can resend the message once it fails
        logger.error('Channel: Sending file message failed!', message);
        message.url = URL.createObjectURL(file);
        message.file = file;
        messagesDispatcher({
          type: messageActionTypes.SEND_MESSAGEGE_FAILURE,
          payload: message,
        });
        return;
      }
      logger.info('Channel: Sending message success!', message);
      messagesDispatcher({
        type: messageActionTypes.SEND_MESSAGEGE_SUCESS,
        payload: message,
      });
    });
    pubSub.publish(topics.SEND_MESSAGE_START, {
      /* pubSub is used instead of messagesDispatcher
        to avoid redundantly calling `messageActionTypes.SEND_MESSAGEGE_START` */
      message: {
        ...pendingMsg,
        url: URL.createObjectURL(file),
        // pending thumbnail message seems to be failed
        requestState: 'pending',
      },
      channel: currentGroupChannel,
    });
    setTimeout(() => utils.scrollIntoLast('.sendbird-msg--scroll-ref'), 1000);
  }, [currentGroupChannel, onBeforeSendFileMessage]);
  return [sendMessage];
}
