import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
  getMessageCreatedAt,
  getIsSentFromStatus,
} from './util';

import Avatar from '../Avatar';
import Label, { LabelTypography, LabelColors } from '../Label';
import ContextMenu, { MenuItem, MenuItems } from '../ContextMenu';
import IconButton from '../IconButton';
import Icon, { IconTypes, IconColors } from '../Icon';
import MessageStatus from '../MessageStatus';
import {
  isImage,
  isVideo,
  unSupported,
} from '../FileViewer/types';
import {
  getSenderName,
  getSenderProfileUrl,
} from '../../utils/utils';
import EmojiReactions from '../EmojiReactions';

const noop = () => { };
const SPACE_BETWEEN_MORE = 4;
const MORE_WIDTH = 32;

export default function ThumbnailMessage({
  message = {},
  userId,
  disabled,
  isByMe,
  onClick,
  showRemove,
  status,
  resendMessage,
  useReaction,
  emojiAllMap,
  membersMap,
  toggleReaction,
  memoizedEmojiListItems,
}) {
  const {
    type,
    url,
  } = message;
  const parentContainRef = useRef(null);
  const menuRef = useRef(null);
  const reactionAddRef = useRef(null);
  const showReactionAddButton = (useReaction && emojiAllMap && emojiAllMap.size > 0);
  const MemoizedEmojiListItems = memoizedEmojiListItems;
  const isMessageSent = getIsSentFromStatus(status);

  return (
    <div
      className={[
        'sendbird-thumbnail',
        (!isByMe ? 'sendbird-thumbnail--incoming' : ''),
      ].join(' ')}
    >
      {
        !isByMe && (
          <>
            <Avatar
              className="sendbird-thumbnail__avatar"
              src={getSenderProfileUrl(message)}
              width="28px"
              height="28px"
            />
            <Label
              className="sendbird-thumbnail__sender-name"
              type={LabelTypography.CAPTION_2}
              color={LabelColors.ONBACKGROUND_2}
            >
              {getSenderName(message) || ''}
            </Label>
          </>
        )
      }
      <div className="sendbird-thumbnail__body">
        {
          isByMe && (
            <div
              className="sendbird-thumbnail__more"
              ref={parentContainRef}
              style={{ left: `-${SPACE_BETWEEN_MORE + (showReactionAddButton ? MORE_WIDTH * 2 : MORE_WIDTH)}px` }}
            >
              <ContextMenu
                menuTrigger={(toggleDropdown) => (
                  <IconButton
                    ref={menuRef}
                    width="32px"
                    height="32px"
                    onClick={toggleDropdown}
                  >
                    <Icon
                      width="24px"
                      height="24px"
                      type={IconTypes.MORE}
                      color={IconColors.CONTENT_INVERSE}
                    />
                  </IconButton>
                )}
                menuItems={(closeDropdown) => (
                  <MenuItems
                    parentRef={menuRef}
                    parentContainRef={parentContainRef}
                    closeDropdown={closeDropdown}
                    openLeft
                  >
                    <MenuItem onClick={() => {
                      if (disabled) { return; }
                      showRemove(true);
                      closeDropdown();
                    }}
                    >
                      Delete
                    </MenuItem>
                  </MenuItems>
                )}
              />
              {
                isMessageSent && showReactionAddButton
                && (
                  <ContextMenu
                    menuTrigger={(toggleDropdown) => (
                      <IconButton
                        ref={reactionAddRef}
                        onClick={toggleDropdown}
                        width="32px"
                        height="32px"
                      >
                        <Icon
                          width="24px"
                          height="24px"
                          type={IconTypes.EMOJI_REACTIONS_ADD}
                          color={IconColors.CONTENT_INVERSE}
                        />
                      </IconButton>
                    )}
                    menuItems={(closeDropdown) => (
                      <MemoizedEmojiListItems
                        message={message}
                        parentRef={reactionAddRef}
                        parentContainRef={parentContainRef}
                        closeDropdown={closeDropdown}
                        spaceFromTrigger={{ y: 2 }}
                      />
                    )}
                  />
                )
              }
            </div>
          )
        }
        <div
          className="sendbird-thumbnail__wrap"
        >
          <div
            className="sendbird-thumbnail__wrap__inner"
            onClick={isMessageSent ? () => onClick(true) : () => { }}
            onKeyDown={isMessageSent ? () => onClick(true) : () => { }}
            tabIndex={0}
            role="button"
          >
            {
              isVideo(type) && (
                <>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video
                    className="sendbird-thumbnail__video"
                  >
                    <source src={url} type={type} />
                  </video>
                  <Icon
                    className="sendbird-thumbnail__video-icon"
                    width="56px"
                    height="56px"
                    type={IconTypes.PLAY}
                  />
                </>
              )
            }
            {
              isImage(type) && (
                <div
                  className="sendbird-thumbnail__img"
                  style={{
                    backgroundImage: `url(${url})`,
                    height: '280px',
                    width: '404px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                />
              )
            }
            {
              unSupported(type) && (
                <div className="sendbird-thumbnail__other">
                  Unknown type
                </div>
              )
            }
            <div className="sendbird-thumbnail__wrap-overlay" />
          </div>
          {
            (useReaction && message.reactions && message.reactions.length > 0)
            && (
              <EmojiReactions
                className="sendbird-thumbnail__wrap__emoji-reactions"
                userId={userId}
                message={message}
                emojiAllMap={emojiAllMap}
                membersMap={membersMap}
                toggleReaction={toggleReaction}
                memoizedEmojiListItems={memoizedEmojiListItems}
              />
            )
          }
        </div>
        {
          isByMe
            ? (
              <div className="sendbird-thumbnail__status">
                <MessageStatus
                  message={message}
                  status={status}
                  onDelete={() => { showRemove(true); }}
                  onResend={() => resendMessage(message)}
                />
              </div>
            )
            : (
              <Label
                className="sendbird-thumbnail__sent-at"
                type={LabelTypography.CAPTION_3}
                color={LabelColors.ONBACKGROUND_2}
              >
                {getMessageCreatedAt(message)}
              </Label>
            )
        }
        {
          (!isByMe && showReactionAddButton)
          && (
            <div
              className="sendbird-thumbnail__more"
              ref={parentContainRef}
              style={{ right: `-${SPACE_BETWEEN_MORE + MORE_WIDTH}px` }}
            >
              <ContextMenu
                menuTrigger={(toggleDropdown) => (
                  <IconButton
                    ref={reactionAddRef}
                    onClick={toggleDropdown}
                    width="32px"
                    height="32px"
                  >
                    <Icon
                      width="24px"
                      height="24px"
                      type={IconTypes.EMOJI_REACTIONS_ADD}
                      color={IconColors.CONTENT_INVERSE}
                    />
                  </IconButton>
                )}
                menuItems={(closeDropdown) => (
                  <MemoizedEmojiListItems
                    message={message}
                    parentRef={reactionAddRef}
                    parentContainRef={parentContainRef}
                    closeDropdown={closeDropdown}
                    spaceFromTrigger={{ y: 2 }}
                  />
                )}
              />
            </div>
          )
        }
      </div>
    </div>
  );
}

ThumbnailMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string,
  resendMessage: PropTypes.func,
  status: PropTypes.string,
  isByMe: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  showRemove: PropTypes.func,
  useReaction: PropTypes.bool.isRequired,
  emojiAllMap: PropTypes.instanceOf(Map),
  membersMap: PropTypes.instanceOf(Map),
  toggleReaction: PropTypes.func,
  memoizedEmojiListItems: PropTypes.func,
};

ThumbnailMessage.defaultProps = {
  isByMe: false,
  disabled: false,
  resendMessage: noop,
  onClick: noop,
  showRemove: noop,
  status: '',
  userId: '',
  emojiAllMap: new Map(),
  membersMap: new Map(),
  toggleReaction: noop,
  memoizedEmojiListItems: () => '',
};
