import moment from 'moment';

import * as actionTypes from './actionTypes';
import initialState from './initialState';

import compareIds from '../../../utils/compareIds';

export default function reducer(state, action) {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return initialState;
    case actionTypes.RESET_MESSAGES:
      return {
        ...state,
        allMessages: [],
      };
    case actionTypes.GET_PREV_MESSAGES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CLEAR_SENT_MESSAGES:
      return {
        ...state,
        allMessages: [
          ...state.allMessages.filter((m) => (
            m.sendingStatus !== 'succeeded'
          )),
        ],
      };
    case actionTypes.GET_PREV_MESSAGES_SUCESS: {
      const recivedMessages = action.payload.messages || [];
      // remove duplicate messages
      const filteredAllMessages = state.allMessages.filter((msg) => (
        !(recivedMessages.find(({ messageId }) => compareIds(messageId, msg.messageId)))
      ));
      return {
        ...state,
        loading: false,
        initialized: true,
        hasMore: action.payload.hasMore,
        lastMessageTimeStamp: action.payload.lastMessageTimeStamp,
        allMessages: [
          ...recivedMessages,
          ...filteredAllMessages,
        ],
      };
    }
    case actionTypes.SEND_MESSAGEGE_START:
      return {
        ...state,
        allMessages: [
          ...state.allMessages,
          { ...action.payload },
        ],
      };
    case actionTypes.SEND_MESSAGEGE_SUCESS:
      return {
        ...state,
        allMessages: state.allMessages.map((m) => (
          compareIds(m.reqId, action.payload.reqId)
            ? action.payload
            : m
        )),
      };
    case actionTypes.SEND_MESSAGEGE_FAILURE: {
      // eslint-disable-next-line no-param-reassign
      action.payload.failed = true;
      return {
        ...state,
        allMessages: state.allMessages.map((m) => (
          compareIds(m.reqId, action.payload.reqId)
            ? action.payload
            : m
        )),
      };
    }
    case actionTypes.SET_CURRENT_CHANNEL: {
      return {
        ...state,
        currentGroupChannel: action.payload,
        isInvalid: false,
      };
    }
    case actionTypes.SET_CHANNEL_INVALID: {
      return {
        ...state,
        isInvalid: true,
      };
    }
    case actionTypes.ON_MESSAGE_RECEIVED: {
      const { channel, message } = action.payload;
      const { currentGroupChannel = {}, unreadCount, unreadSince } = state;
      const currentGroupChannelUrl = currentGroupChannel.url;
      if (!compareIds(channel.url, currentGroupChannelUrl)) {
        return state;
      }
      // Excluded overlapping messages
      if (!(state.allMessages.map((msg) => msg.messageId).indexOf(message.messageId) < 0)) {
        return state;
      }
      if (message.isAdminMessage && message.isAdminMessage()) {
        return {
          ...state,
          allMessages: [...state.allMessages, message],
        };
      }
      return {
        ...state,
        unreadCount: unreadCount + 1,
        unreadSince: (unreadCount === 0)
          ? moment().format('LT MMM DD')
          : unreadSince,
        allMessages: [...state.allMessages, message],
      };
    }
    case actionTypes.ON_MESSAGE_UPDATED:
      return {
        ...state,
        allMessages: state.allMessages.map((m) => (
          compareIds(m.messageId, action.payload.messageId)
            ? action.payload
            : m
        )),
      };
    case actionTypes.RESEND_MESSAGEGE_START:
      return {
        ...state,
        allMessages: state.allMessages.map((m) => (
          compareIds(m.reqId, action.payload.reqId)
            ? action.payload
            : m
        )),
      };
    case actionTypes.MARK_AS_READ:
      return {
        ...state,
        unreadCount: 0,
        unreadSince: null,
      };
    case actionTypes.ON_MESSAGE_DELETED:
      return {
        ...state,
        allMessages: state.allMessages.filter((m) => (
          !compareIds(m.messageId, action.payload)
        )),
      };
    case actionTypes.ON_MESSAGE_DELETED_BY_REQ_ID:
      return {
        ...state,
        allMessages: state.allMessages.filter((m) => (
          !compareIds(m.reqId, action.payload)
        )),
      };
    case actionTypes.SET_EMOJI_CONTAINER: {
      return {
        ...state,
        emojiContainer: action.payload,
      };
    }
    case actionTypes.SET_READ_STATUS: {
      return {
        ...state,
        readStatus: action.payload,
      };
    }
    case actionTypes.ON_REACTION_UPDATED: {
      return {
        ...state,
        allMessages: state.allMessages.map((m) => {
          if (compareIds(m.messageId, action.payload.messageId)) {
            if (m.applyReactionEvent && typeof m.applyReactionEvent === 'function') {
              m.applyReactionEvent(action.payload);
            }
            return m;
          }
          return m;
        }),
      };
    }
    default:
      return state;
  }
}
