import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Typography, Colors } from './types';
import { changeTypographyToClassName, changeColorToClassName } from './utils';
import getStringSet from './stringSet';

const CLASS_NAME = 'sendbird-label';

export default function Label({
  type,
  color,
  children,
  className,
}) {
  const injectingClassName = (Array.isArray(className))
    ? [CLASS_NAME, ...className]
    : [CLASS_NAME, className];
  if (type) {
    injectingClassName.push(changeTypographyToClassName(type));
  }
  if (color) {
    injectingClassName.push(changeColorToClassName(color));
  }

  return (
    <div
      className={injectingClassName.join(' ')}
    >
      {children}
    </div>
  );
}

Label.propTypes = {
  type: PropTypes.oneOf([...Object.keys(Typography), '']),
  color: PropTypes.oneOf([...Object.keys(Colors), '']),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.any,
  ]),
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

Label.defaultProps = {
  type: '',
  color: '',
  className: [],
  children: null,
};

const LabelTypography = Typography;
const LabelColors = Colors;
const LabelStringSet = getStringSet('en');
export { LabelTypography, LabelColors, LabelStringSet };
