import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Icon, { IconTypes, IconColors } from '../Icon';
import Label, { LabelColors, LabelTypography, LabelStringSet } from '../Label';
import Loader from '../Loader';
import MessageStatusType from './type';

import { getMessageCreatedAt } from '../../utils/utils';

const MessageStatusTypes = MessageStatusType;
export { MessageStatusTypes };
export default function MessageStatus({
  message,
  status,
  className,
  onDelete,
  onResend,
}) {
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  const canResend = message && typeof message.isResendable === 'function'
  && message.isResendable();
  const label = () => {
    switch (status) {
      case MessageStatusType.PENDING: {
        return null;
      }
      case MessageStatusType.SENT:
      case MessageStatusType.DELIVERED:
      case MessageStatusType.READ: {
        return (
          <Label
            className="sendbird-message-status__text"
            type={LabelTypography.CAPTION_3}
            color={LabelColors.ONBACKGROUND_2}
          >
            {getMessageCreatedAt(message)}
          </Label>
        );
      }
      case MessageStatusType.FAILED: {
        // check onClickEvents
        return (
          <Label
            className="sendbird-message-status__text"
            type={LabelTypography.CAPTION_3}
            color={LabelColors.ERROR}
          >
            {LabelStringSet.MESSAGE_STATUS__SENDING_FAILED}
            {
              canResend && (
                <>
                  <span
                    className="sendbird-message-status__text__try-again"
                    role="button"
                    tabIndex={0}
                    onClick={onResend}
                    onKeyPress={onResend}
                  >
                    {LabelStringSet.MESSAGE_STATUS__TRY_AGAIN}
                  </span>
                  {LabelStringSet.MESSAGE_STATUS__OR}
                </>
              )
            }
            <span
              className="sendbird-message-status__text__delete"
              role="button"
              tabIndex={0}
              onClick={onDelete}
              onKeyPress={onDelete}
            >
              {LabelStringSet.MESSAGE_STATUS__DELETE}
            </span>
          </Label>
        );
      }
      default: return null;
    }
  };

  const icon = {
    PENDING: (
      <Loader className="sendbird-message-status__icon" width="16px" height="16px">
        <Icon type={IconTypes.SPINNER} width="16px" height="16px" />
      </Loader>
    ),
    SENT: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.SENT} fillColor={IconColors.SENT} />,
    DELIVERED: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.DELIVERED} fillColor={IconColors.SENT} />,
    READ: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.READ} fillColor={IconColors.READ} />,
    FAILED: <Icon className="sendbird-message-status__icon" width="16px" height="16px" type={IconTypes.ERROR} />,
  };

  return (
    <div className={[...injectingClassName, 'sendbird-message-status'].join(' ')}>
      {label(status)}
      {icon[status]}
    </div>
  );
}

MessageStatus.propTypes = {
  message: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ])),
  status: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onDelete: PropTypes.func,
  onResend: PropTypes.func,
};

MessageStatus.defaultProps = {
  message: null,
  status: '',
  className: '',
  onDelete: () => {},
  onResend: () => {},
};
