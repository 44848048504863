import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withSendbirdContext from '../../lib/SendbirdSdkContext';

import './index.scss';
import Label, { LabelTypography, LabelColors, LabelStringSet } from '../../ui/Label';
import Icon, { IconTypes, IconColors } from '../../ui/Icon';

import ChannelProfile from './components/ChannelProfile';
import MemebersAccordion from './components/MemebersAccordion';
import LeaveChannelModal from './components/LeaveChannel';
import PlaceHolder from '../../ui/PlaceHolder';
import PlaceHolderTypes from '../../ui/PlaceHolder/type';
import { uuidv4 } from '../../utils/uuid.ts';
import { createDefaultUserListQuery } from '../ChannelList/components/utils';

function ChannelSettings(props) {
  const {
    onCloseClick,
    channelUrl,
    onChannelModified,
    onBeforeUpdateChannel,
  } = props;

  const {
    stores: { sdkStore },
    config: {
      userListQuery,
      theme,
      userId,
      logger,
      isOnline,
    },
    queries = {},
  } = props;

  const userFilledApplicationUserListQuery = queries.applicationUserListQuery;

  const { sdk, initialized } = sdkStore;

  // hack to kepp track of channel updates by triggering useEffect
  const [channelUpdateId, setChannelUpdateId] = useState(uuidv4());
  const [channel, setChannel] = useState(null);
  const [invalidChannel, setInvalidChannel] = useState(false);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showLeaveChannelModal, setShowLeaveChannelModal] = useState(false);

  useEffect(() => {
    logger.info('ChannelSettings: Setting up');
    if (!channelUrl || !initialized || !sdk) {
      logger.warning('ChannelSettings: Setting up failed', 'No channelUrl or sdk uninitialized');
      setInvalidChannel(false);
    } else {
      if (!sdk || !sdk.GroupChannel) {
        logger.warning('ChannelSettings: No GroupChannel');
        return;
      }
      sdk.GroupChannel.getChannel(channelUrl, (groupChannel) => {
        if (!groupChannel) {
          logger.warning('ChannelSettings: Channel not found');
          setInvalidChannel(true);
        } else {
          logger.info('ChannelSettings: Fetched group channel', groupChannel);
          setInvalidChannel(false);
          setChannel(groupChannel);
        }
      });
    }
  }, [channelUrl, initialized, channelUpdateId]);

  if (!channel || invalidChannel) {
    return (
      <div className="sendbird-channel-settings">
        <div className="sendbird-channel-settings__header">
          <Label type={LabelTypography.H_2} color={LabelColors.ONBACKGROUND_1}>
            {LabelStringSet.CHANNEL_SETTING__HEADER__TITLE}
          </Label>
          <Icon
            type={IconTypes.CLOSE}
            className="sendbird-channel-settings__close-icon"
            height="24px"
            width="24px"
            onClick={() => {
              logger.info('ChannelSettings: Click close');
              onCloseClick();
            }}
          />
        </div>
        <div>
          <PlaceHolder type={PlaceHolderTypes.WRONG} />
        </div>
      </div>
    );
  }

  return (
    <div className="sendbird-channel-settings">
      <div className="sendbird-channel-settings__header">
        <Label type={LabelTypography.H_2} color={LabelColors.ONBACKGROUND_1}>
          {LabelStringSet.CHANNEL_SETTING__HEADER__TITLE}
        </Label>
        <Icon
          type={IconTypes.CLOSE}
          className="sendbird-channel-settings__close-icon"
          height="24px"
          width="24px"
          onClick={() => {
            logger.info('ChannelSettings: Click close');
            onCloseClick();
          }}
        />
      </div>
      <div className="sendbird-channel-settings__scroll-area">
        <ChannelProfile
          disabled={!isOnline}
          channel={channel}
          userId={userId}
          theme={theme}
          onChannelInfoChange={(currentImg, currentTitle) => {
            logger.info('ChannelSettings: Channel information being updated');
            const swapParams = sdk.getErrorFirstCallback();
            if (onBeforeUpdateChannel) {
              const params = onBeforeUpdateChannel(currentTitle, currentImg, channel.data);
              // swapParams
              channel.updateChannel(params, (response, error) => {
                let groupChannel = response;
                if (swapParams) {
                  groupChannel = error;
                }

                onChannelModified(groupChannel);
                setChannelUpdateId(uuidv4());
              });
              return;
            }
            channel.updateChannel(currentTitle, currentImg, channel.data, (response, error) => {
              let groupChannel = response;
              if (swapParams) {
                groupChannel = error;
              }
              logger.info('ChannelSettings: Channel information updated', groupChannel);
              onChannelModified(groupChannel);
              setChannelUpdateId(uuidv4());
            });
          }}
        />
        <div
          role="switch"
          aria-checked={showAccordion}
          tabIndex={0}
          onKeyDown={() => setShowAccordion(!showAccordion)}
          className="sendbird-channel-settings__panel-item"
          onClick={() => setShowAccordion(!showAccordion)}
        >
          <Icon
            type={IconTypes.MEMBERS}
            className="sendbird-channel-settings__panel-icon-left"
            height="24px"
            width="24px"
            fillColor={IconColors.PRIMARY}
          />
          <Label
            type={LabelTypography.SUBTITLE_1}
            color={LabelColors.ONBACKGROUND_1}
          >
            {`${LabelStringSet.CHANNEL_SETTING__MEMBERS__TITLE} (${channel.members.length})`}
          </Label>
          <Icon
            type={IconTypes.SHEVRON}
            className={[
              'sendbird-channel-settings__panel-icon-right',
              'sendbird-channel-settings__panel-icon--chevron',
              (showAccordion ? 'sendbird-channel-settings__panel-icon--open' : ''),
            ].join(' ')}
            height="24px"
            width="24px"
          />
        </div>
        {
          showAccordion && (
            <MemebersAccordion
              disabled={!isOnline}
              // eslint-disable-next-line
              userQueryCreator={
                () => ((userListQuery && typeof userListQuery === 'function')
                  ? userListQuery()
                  : createDefaultUserListQuery({ sdk, userFilledApplicationUserListQuery })
                )
              }
              swapParams={
                sdk && sdk.getErrorFirstCallback && sdk.getErrorFirstCallback()
              }
              members={channel.members}
              onInviteMemebers={(selectedMemebers) => {
                logger.info('ChannelSettings: Inviting new users');
                channel.inviteWithUserIds(selectedMemebers)
                  .then((res) => {
                    onChannelModified(res);
                    setChannelUpdateId(uuidv4());
                    logger.info('ChannelSettings: Inviting new users success!', res);
                  });
              }}
            />
          )
        }
        <div
          className={
            `sendbird-channel-settings__panel-item
              ${!isOnline ? 'sendbird-channel-settings__panel-item__disabled' : ''}`
          }
          role="button"
          tabIndex={0}
          disabled
          onKeyDown={() => {
            if (!isOnline) { return; }
            setShowLeaveChannelModal(true);
          }}
          onClick={() => {
            if (!isOnline) { return; }
            setShowLeaveChannelModal(true);
          }}
        >
          <Icon
            type={IconTypes.LEAVE}
            className={[
              'sendbird-channel-settings__panel-icon-left',
              'sendbird-channel-settings__panel-icon__leave',
            ].join(' ')}
            height="24px"
            width="24px"
          />
          <Label
            type={LabelTypography.SUBTITLE_1}
            color={LabelColors.ONBACKGROUND_1}
          >
            {LabelStringSet.CHANNEL_SETTING__LEAVE_CHANNEL__TITLE}
          </Label>
        </div>
        {
          showLeaveChannelModal && (
            <LeaveChannelModal
              onCloseModal={() => setShowLeaveChannelModal(false)}
              onLeaveChannel={() => {
                logger.info('ChannelSettings: Leaving channel', channel);
                channel.leave()
                  .then(() => {
                    logger.info('ChannelSettings: Leaving channel successful!', channel);
                    onCloseClick();
                  });
              }}
            />
          )
        }
      </div>
    </div>
  );
}

ChannelSettings.propTypes = {
  onCloseClick: PropTypes.func,
  onChannelModified: PropTypes.func,
  onBeforeUpdateChannel: PropTypes.func,
  channelUrl: PropTypes.string.isRequired,
  queries: PropTypes.shape({
    applicationUserListQuery: PropTypes.shape({
      limit: PropTypes.number,
      userIdsFilter: PropTypes.arrayOf(PropTypes.string),
      metaDataKeyFilter: PropTypes.string,
      metaDataValuesFilter: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  // from withSendbirdContext
  stores: PropTypes.shape({
    sdkStore: PropTypes.shape({
      sdk: PropTypes.shape({
        getErrorFirstCallback: PropTypes.func,
        GroupChannel: PropTypes.oneOfType([
          PropTypes.shape({
            getChannel: PropTypes.func,
          }),
          PropTypes.func,
        ]),
        createApplicationUserListQuery: PropTypes.any,
      }),
      initialized: PropTypes.bool,
    }),
  }).isRequired,
  config: PropTypes.shape({
    userId: PropTypes.string,
    theme: PropTypes.string,
    userListQuery: PropTypes.func,
    isOnline: PropTypes.bool,
    logger: PropTypes.shape({
      info: PropTypes.func,
      error: PropTypes.func,
      warning: PropTypes.func,
    }),
  }).isRequired,
};

ChannelSettings.defaultProps = {
  onBeforeUpdateChannel: null,
  queries: {},
  onCloseClick: () => { },
  onChannelModified: () => { },
};

export default withSendbirdContext(ChannelSettings);
