import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import * as utils from './utils';

import Avatar from '../Avatar';
import Label, { LabelTypography, LabelColors, LabelStringSet } from '../Label';
import MessageStatus from '../MessageStatus';
import ContextMenu, { MenuItems, MenuItem } from '../ContextMenu';
import IconButton from '../IconButton';
import Icon, { IconColors, IconTypes } from '../Icon';

const CLASS_NAME = 'sendbird-unknown-message';

export default function UnknownMessage({
  message,
  isByMe,
  status,
  className,
  showRemove,
}) {
  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.unshift(CLASS_NAME);
  injectingClassName.push(`${CLASS_NAME}${isByMe ? '--outgoing' : '--incoming'}`);

  return (
    <div className={injectingClassName.join(' ')}>
      {
        isByMe
          ? <OutgoingUnknownMessage message={message} status={status} showRemove={showRemove} />
          : <IncomingUnknownMessage message={message} />
      }
    </div>
  );
}

UnknownMessage.propTypes = {
  message: PropTypes.shape({}).isRequired,
  isByMe: PropTypes.bool,
  status: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showRemove: PropTypes.func,
};

UnknownMessage.defaultProps = {
  isByMe: false,
  status: '',
  className: '',
  showRemove: () => { },
};

function OutgoingUnknownMessage({
  message,
  status,
  showRemove,
}) {
  const className = 'sendbird-outgoing-unknown-message';
  const parentContainRef = useRef(null);
  const menuRef = useRef(null);
  return (
    <div className={className}>
      <div
        className={`${className}__more`}
        ref={parentContainRef}
      >
        <ContextMenu
          menuTrigger={(toggleDropdown) => (
            <IconButton
              className={`${className}__more__menu`}
              ref={menuRef}
              width="32px"
              height="32px"
              onClick={toggleDropdown}
            >
              <Icon
                width="24px"
                height="24px"
                type={IconTypes.MORE}
                fillColor={IconColors.CONTENT_INVERSE}
              />
            </IconButton>
          )}
          menuItems={(closeDropdown) => (
            <MenuItems
              parentRef={menuRef} // for catching location(x, y) of MenuItems
              parentContainRef={parentContainRef} // for toggling more options(menus & reactions)
              closeDropdown={closeDropdown}
              openLeft
            >
              <MenuItem
                onClick={() => { showRemove(true); closeDropdown(); }}
              >
                Delete
              </MenuItem>
            </MenuItems>
          )}
        />
      </div>
      <div className={`${className}__body`}>
        <div className={`${className}__body__text-balloon`}>
          <Label
            className={`${className}__body__text-balloon__header`}
            type={LabelTypography.BODY_1}
            color={LabelColors.ONBACKGROUND_1}
          >
            {LabelStringSet.UNKNOWN__UNKNOWN_MESSAGE_TYPE}
          </Label>
          <Label
            className={`${className}__body__text-balloon__description`}
            type={LabelTypography.BODY_1}
            color={LabelColors.ONBACKGROUND_2}
          >
            {LabelStringSet.UNKNOWN__CANNOT_READ_MESSAGE}
          </Label>
        </div>
        <div className={`${className}__body__message-status`}>
          <MessageStatus
            message={message}
            status={status}
          />
        </div>
      </div>
    </div>
  );
}

function IncomingUnknownMessage({
  message,
}) {
  const className = 'sendbird-incoming-unknown-message';
  const { sender } = message;
  return (
    <div className={className}>
      <div className={`${className}__left`}>
        <Avatar
          className={`${className}__left__sender-profile-image`}
          src={sender.profileUrl || ''}
          width="26px"
          height="26px"
          alt="sender-profile-image"
        />
      </div>
      <div className={`${className}__body`}>
        <Label
          className={`${className}__body__sender-name`}
          type={LabelTypography.CAPTION_2}
          color={LabelColors.ONBACKGROUND_2}
        >
          {sender.nickname || LabelStringSet.NO_NAME}
        </Label>
        <div className={`${className}__body__text-balloon`}>
          <Label
            className={`${className}__body__text-balloon__header`}
            type={LabelTypography.BODY_1}
            color={LabelColors.ONBACKGROUND_1}
          >
            {LabelStringSet.UNKNOWN__UNKNOWN_MESSAGE_TYPE}
          </Label>
          <Label
            className={`${className}__body__text-balloon__description`}
            type={LabelTypography.BODY_1}
            color={LabelColors.ONBACKGROUND_2}
          >
            {LabelStringSet.UNKNOWN__CANNOT_READ_MESSAGE}
          </Label>
        </div>
        <Label
          className={`${className}__body__sent-at`}
          type={LabelTypography.CAPTION_3}
          color={LabelColors.ONBACKGROUND_2}
        >
          {utils.getMessageCreatedAt(message)}
        </Label>
      </div>
    </div>
  );
}

OutgoingUnknownMessage.propTypes = {
  message: PropTypes.shape({}).isRequired,
  status: PropTypes.string.isRequired,
  showRemove: PropTypes.func,
};

OutgoingUnknownMessage.defaultProps = {
  showRemove: () => { },
};

IncomingUnknownMessage.propTypes = {
  message: PropTypes.shape({
    sender: PropTypes.shape({
      nickname: PropTypes.string,
      profileUrl: PropTypes.string,
    }),
  }).isRequired,
};
