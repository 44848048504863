import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../Avatar';
import Checkbox from '../Checkbox';
import Label, { LabelStringSet, LabelTypography, LabelColors } from '../Label';

import './index.scss';

const COMPONENT_NAME = 'sendbird-user-list-item';

export default function UserListItem({
  user,
  className,
  checkBox,
  checked,
  onChange,
}) {
  const injectingClassNames = Array.isArray(className) ? className : [className];
  const uniqueKey = user.userId;
  return (
    <div
      className={[COMPONENT_NAME, ...injectingClassNames].join(' ')}
    >
      <Avatar
        className={`${COMPONENT_NAME}__avatar`}
        src={user.profileUrl}
        width="40px"
        height="40px"
      />
      <Label
        className={`${COMPONENT_NAME}__title`}
        type={LabelTypography.SUBTITLE_1}
        color={LabelColors.ONBACKGROUND_1}
      >
        {user.nickname || LabelStringSet.NO_NAME}
      </Label>
      { // if there is now nickname, display userId
        !user.nickname && (
          <Label
            className={`${COMPONENT_NAME}__subtitle`}
            type={LabelTypography.CAPTION_3}
            color={LabelColors.ONBACKGROUND_2}
          >
            {user.userId}
          </Label>
        )
      }
      {
        checkBox && (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label
            className={`${COMPONENT_NAME}__checkbox`}
            htmlFor={uniqueKey}
          >
            <Checkbox
              id={uniqueKey}
              checked={checked}
              onChange={(event) => onChange(event)}
            />
          </label>
        )
      }
    </div>
  );
}

UserListItem.propTypes = {
  user: PropTypes.shape({
    userId: PropTypes.string,
    nickname: PropTypes.string,
    profileUrl: PropTypes.string,
  }).isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  checkBox: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};


UserListItem.defaultProps = {
  className: '',
  checkBox: false,
  checked: false,
  onChange: () => { },
};
