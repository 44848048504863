export const createDefaultUserListQuery = ({ sdk, userFilledApplicationUserListQuery = {} }) => {
  const params = sdk.createApplicationUserListQuery();
  if (userFilledApplicationUserListQuery) {
    Object.keys(userFilledApplicationUserListQuery).forEach((key) => {
      params[key] = userFilledApplicationUserListQuery[key];
    });
  }
  return params;
};

export const createChannel = (
  sdk,
  selectedUsers,
  onBeforeCreateChannel,
) => new Promise((resolve, reject) => {
  // have custom params
  if (onBeforeCreateChannel) {
    const params = onBeforeCreateChannel(selectedUsers);
    sdk.GroupChannel.createChannel(params, (response, error) => {
      const swapParams = sdk.getErrorFirstCallback();
      let groupChannel = response;
      let err = error;
      if (swapParams) {
        groupChannel = error;
        err = response;
      }
      if (err) {
        reject(err);
      }
      resolve(groupChannel);
    });
    return;
  }

  // do not have custom params
  sdk.GroupChannel.createChannelWithUserIds(
    selectedUsers,
    false, // 'distinct' is false
    '', // NAME
    '', // COVER_IMAGE_OR_URL
    '', // DATA
    (response, error) => {
      const swapParams = sdk.getErrorFirstCallback();
      let groupChannel = response;
      let err = error;
      if (swapParams) {
        groupChannel = error;
        err = response;
      }
      if (err) {
        reject(err);
      }
      resolve(groupChannel);
    },
  );
});

export default createChannel;
